<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="efidePartnerDataForm">
      <b-form @submit.prevent="validationForm">
        <b-card>
          <b-row
            class="px-2"
          >
            <b-col sm="12">
              <!-- is EfidePartner -->
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label-cols-sm="12"
                content-cols-sm="12"
                label="¿Fue referenciado por un cliente de Efide?"
                label-for="isEfidePartner"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Validar si eres socio Efide"
                  vid="isEfidePartner"
                >
                  <b-form-radio
                    id="isEfidePartner1"
                    v-model="isEfidePartner"
                    name="isEfidePartner"
                    :aria-describedby="ariaDescribedby"
                    value="yes"
                    class="my-1"
                  >
                    Sí
                  </b-form-radio>
                  <b-form-radio
                    id="isEfidePartner2"
                    v-model="isEfidePartner"
                    name="isEfidePartner"
                    :aria-describedby="ariaDescribedby"
                    value="no"
                    class="my-1"
                  >
                    No
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- EfidePartner Form -->
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-show="isEfidePartner=='yes'"
        >
          <b-row
            class="justify-content-center mt-5 mb-5"
          >
            <b-col
              style="text-align: center"
              lg="12"
            >
              <h4 class="mb-3">
                REFERENCIAS
              </h4>
            </b-col>
            <!-- paternal lastname Efide Partner -->
            <b-col md="8">
              <b-form-group
                label="Apellido paterno"
                label-for="efidePartnerPLastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Apellido Paterno"
                  rules="max:20"
                  vid="efidePartnerPLastname"
                >
                  <b-form-input
                    id="efidePartnerPLastname"
                    v-model="efidePartnerPLastname"
                    :state="errors.length > 0 ? false:null"
                    name="efidePartnerPLastname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- maternal lastname Efide Partner-->
            <b-col md="8">
              <b-form-group
                label="Apellido materno"
                label-for="efidePartnerMLastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Apellido Materno"
                  rules="max:20"
                  vid="efidePartnerMLastname"
                >
                  <b-form-input
                    id="efidePartnerMLastname"
                    v-model="efidePartnerMLastname"
                    :state="errors.length > 0 ? false:null"
                    name="efidePartnerMLastname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- names Efide Partner-->
            <b-col md="8">
              <b-form-group
                label="Nombres"
                label-for="efidePartnerNames"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombres"
                  rules="max:100"
                  vid="efidePartnerNames"
                >
                  <b-form-input
                    id="efidePartnerNames"
                    v-model="efidePartnerNames"
                    :state="errors.length > 0 ? false:null"
                    name="efidePartnerNames"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- phone number Efide Partner-->
            <b-col md="8">
              <b-form-group
                label="Número de teléfono"
                label-for="efidePartnerPhoneNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Número de Documento"
                  vid="efidePartnerPhoneNumber"
                >
                  <b-form-input
                    id="efidePartnerPhoneNumber"
                    v-model="efidePartnerPhoneNumber"
                    :state="errors.length > 0 ? false:null"
                    name="efidePartnerPhoneNumber"
                    :formatter="formatterTel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- relationship Efide Partner -->
            <b-col md="8">
              <b-form-group
                label="Parentesco"
                label-for="efidePartnerRelationship"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Parentesco"
                  rules="max:20"
                  vid="efidePartnerRelationship"
                >
                  <b-form-input
                    id="efidePartnerRelationship"
                    v-model="efidePartnerRelationship"
                    :state="errors.length > 0 ? false:null"
                    name="efidePartnerRelationship"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row
          class="justify-content-center mt-4 mb-4"
        >
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              :to="{name:'Family-data'}"
              block
            >
              Volver
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar y avanzar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Options values
      isEfidePartner: 'no',
      required,
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      efidePartnerPLastname: '',
      efidePartnerMLastname: '',
      efidePartnerNames: '',
      efidePartnerPhoneNumber: '',
      efidePartnerRelationship: '',
      efidePartnerIsComplete: '0', // 0: No --- 1: Si
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.isEfidePartner = dataU.efidePartnerData && dataU.efidePartnerData.isEfidePartner ? dataU.efidePartnerData.isEfidePartner : 'no'
    this.efidePartnerPLastname = dataU.efidePartnerData && dataU.efidePartnerData.pLastname ? dataU.efidePartnerData.pLastname : ''
    this.efidePartnerMLastname = dataU.efidePartnerData && dataU.efidePartnerData.mLastname ? dataU.efidePartnerData.mLastname : ''
    this.efidePartnerNames = dataU.efidePartnerData && dataU.efidePartnerData.names ? dataU.efidePartnerData.names : ''
    this.efidePartnerPhoneNumber = dataU.efidePartnerData && dataU.efidePartnerData.phoneNumber ? dataU.efidePartnerData.phoneNumber : ''
    this.efidePartnerRelationship = dataU.efidePartnerData && dataU.efidePartnerData.relationship ? dataU.efidePartnerData.relationship : ''
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      this.$refs.efidePartnerDataForm.validate().then(success => {
        if (success) {
          useJwt.efidePartnerData({
            email: this.email,
            ruc: this.ruc,
            efidePartnerData: {
              isEfidePartner: this.isEfidePartner,
              pLastname: this.isEfidePartner === 'yes' ? this.efidePartnerPLastname : '',
              mLastname: this.isEfidePartner === 'yes' ? this.efidePartnerMLastname : '',
              names: this.isEfidePartner === 'yes' ? this.efidePartnerNames : '',
              phoneNumber: this.isEfidePartner === 'yes' ? this.efidePartnerPhoneNumber : '',
              relationship: this.isEfidePartner === 'yes' ? this.efidePartnerRelationship : '',
            },
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'EfidePartner-data', status: user.efidePartnerData.completed },
                { root: true },
              )
              this.$router.replace('/registro/datos-bancarios')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.efidePartnerDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
